<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/programs/recreational-pilot-certificate-800.png')" title="RECREATIONAL PILOT CERTIFICATE" subtitle="The beginning of your journey"/>
    <div class="program-description">
      <h1>What is a Recreational Pilot Certificate (RPC)?</h1>
      <p>
        The first licence you will obtain when learning to fly with Flightscope in Brisbane is the RPC issued by Recreational Aviation Australia (RA-Aus).
        <br>
        This allows you to fly a Light Sport Aircraft within 25 nautical miles of your departure point during the day, outside of controlled airspace.
      </p>

      <br>

      <h1>Requirements</h1>
      <div class="list-container">
        <ul class="program-list">
          <li><b>Minimum hours:</b> 20 hours (15 hours dual &amp; 5 hours solo)</li>
          <li><b>Medical standard</b> of a drivers licence</li>
          <li><b>Minimum age:</b> 15 years</li>
          <li><b>Five Theory Exams:</b>
            <ul class="program-list">
              <li>Pre-solo Air Law</li>
              <li>BAK (Basic Aeronautical Knowledge) including Human Factors</li>
              <li>Air Legislation</li>
              <li>RA-Aus Flight Radio</li>
            </ul>
          </li>
        </ul>
      </div>

      <br>

      <h1>What does flight training involve?</h1>
      <p>
        Learning to fly is not just all practical sequences. There are five theory exams that must be passed alongside your training. Once you have taken a trial flight with us and decided you've been bitten by the flying bug, you will learn how to climb, descend and turn the aircraft. <br> <br>
        You will then progress to learning how to fly a 'circuit' and make landings unassisted. As your confidence grows, you will be sent on your first solo flight &mdash; a flight that every pilot remembers with fondness. You will continue to fly with an instructor after your first solo until your skills are up to standard to receive an RA-Aus Pilot Certificate. <br> <br>
        Make sure you also put aside time to prepare and study between lessons so that you can achieve your Pilot Certificate sooner rather than later &mdash; the number one problem with some students is that they're understandably keen for the flying part but they forget about the theory, which is just as important. Flightscope Aviation runs full-time courses for those who are dedicated to achieving their certificates in the minimum period of time.
      </p>

      <br>

      <h1>Who can get a Pilot Certificate?</h1>
      <p>
        RA-Aus is very flexible &mdash; students from 14 up to their mid-70s! As long as you have the health standard to drive a vehicle on public roads, you're healthy enough to fly an RA-Aus aircraft &mdash; even if you have glasses!
      </p>

      <br>

      <h1>Costs &amp; Payment</h1>
      <p>
        At Flightscope, we keep things simple. Pay on the day, no need for upfront deposits. We don't charge for fuel or headset rental.
      </p>
    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
@include program-page;
</style>
